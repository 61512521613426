import React from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'


function MapView({ defaultLocation, defaultZoom, markers, onRegionChange, onClick, ...otherProps }) {

  const handleMapChange = ({ target: { _zoom, _lastCenter } }) => {
    if (!onRegionChange) return;
    onRegionChange({
      zoom: _zoom,
      center: _lastCenter
    })
  }

  return (
    <Map
      center={defaultLocation}
      zoom={defaultZoom || 13}
      onzoomend={handleMapChange}
      onmoveend={handleMapChange}
      onclick={({ latlng }) => !!onClick && onClick([latlng.lat, latlng.lng])}
      {...otherProps}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markers && markers.map(marker => (
        <Marker key={`mmk_${marker.id}`} position={marker.location}>
          <Popup>{marker.id}</Popup>
        </Marker>
      ))}
    </Map>
  )
}

export default MapView
