import React from 'react'
import { Provider as UrqlProvider, Client, dedupExchange, fetchExchange } from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'

const cache = cacheExchange({})

let urqlOptions = {
  url: process.env.REACT_APP_HASURA_URL,
  exchanges: [dedupExchange, cache, fetchExchange],
  fetchOptions: { 
    credentials: 'include',
    headers: { "X-Hasura-Role": "guest"}
  }
}
const client = new Client(urqlOptions);

const GraphqlProvider = (props) => (
  <UrqlProvider value={client}>
    {props.children}
  </UrqlProvider>
);

export default GraphqlProvider;