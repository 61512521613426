import React from 'react'
import logoImg from '../../../Assets/Img/citoy-logo-300x116.png'
import AlertMenu from '../../Alerts/AlertMenu'
import UserMenu from '../../User/UserMenu'
import MessageMenu from '../../Messages/MessageMenu'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <>
      <nav className="navbar navbar-expand-lg header fixed-top">
        <Link to="/" className="navbar-brand" >
          <img src={logoImg} alt="Association citoyliens" className="navbar-logo" height="auto" />
        </Link>
        <button className="navbar-toggler btn btn-link nav-link" type="button">
          <i className="navbar-toggler-icon fa fa-bars" />
        </button>
        <div className="d-flex justify-content-end flex-grow-1">
            <MessageMenu />
            <AlertMenu />
        </div>
        <div className="collapse navbar-collapse flex-grow-0">
          <ul className="navbar-nav ml-auto justify-content-center">
            <UserMenu />
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Header
