import { useCallback } from 'react'

const GEONAMES_URL = "http://api.geonames.org/geoCodeAddressJSON"
// q=Museumplein+6+amsterdam&username=citoyliens"

export const useGeonamesGeocoding = () => {
  const geocode = useCallback(async (query) => {
    if (!query || String(query).length < 5) return;
    const url = `${GEONAMES_URL}?q=${query},france&username=${process.env.REACT_APP_GEONAMES_USERNAME}`
    const response = await fetch(url);
    const json = await response.json();
    return json?.address || null
  }, [])

  return geocode;
};