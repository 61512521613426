import React from 'react'
import { useRouteMatch, Link } from 'react-router-dom'

function SidebarLink({icon, label, link}) {
  const active = useRouteMatch({path: link, strict: true, sensitive: true});
  return (
    <li className={`list-group-item ${active && active.isExact ? 'active' : ''} sidebar-link`}>
    <Link to={link}>
      <i className={`fa fa-fw fa-${icon}`} />
      <span className="label">{label}</span>
    </Link>
    </li>
  )
}

export default SidebarLink
