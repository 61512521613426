import React, { createContext, useReducer } from 'react';
import UserActions, { initialState } from './UserActions';


export const UserContext = createContext();

const UserContextProvider = ({children}) => {
  const init = initialState(false);
  const [state, dispatch] = useReducer(UserActions, init)

  return (
    <UserContext.Provider value={{state, dispatch}}>
      {children}      
    </UserContext.Provider>
  )
}

export default UserContextProvider
