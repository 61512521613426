import React, { useCallback, useEffect, useRef, useState } from 'react'
import MapView from '../MapView/MapView'
import { useGeonamesReverse } from './useGeonameReverse'
import { useGeonamesGeocoding } from './useGeonamesGeocoding'

const defaultLocation = [45.158464, 1.533651] // Centre de brive

const GeonamesInput = ({ onChange, ...inputProps }) => {
  const [query, setQuery] = useState("")
  const [pending, setPending] = useState(false)
  const [mapLocation, setMapLocation] = useState(defaultLocation)
  const [mapZoom, setMapZoom] = useState(11)
  const [markers, setMarkers] = useState([])
  const geocode = useGeonamesGeocoding()
  const reverse = useGeonamesReverse()

  const timeoutRef = useRef()

  const handleGeocoding = useCallback((geocoding) => {
    if (!!geocoding) {
      const { lat, lng, houseNumber, street, postalcode, locality } = geocoding;
      const location = [lat, lng];
      setQuery(`${houseNumber}, ${street}, ${postalcode} ${locality}`)
      setMapLocation(location)
      setMarkers([{ location }])
      setPending(false)
      onChange(geocoding)
    }

  }, [])

  const handleInputChange = useCallback(({ target: { value } }) => {
    setQuery(value)
    clearTimeout(timeoutRef.current)
    if (value.length > 8) {
      setPending(true)
      timeoutRef.current = setTimeout(() => {
        geocode(value).then(handleGeocoding)
      }, 500)
    }
  }, [])

  const handleMapClick = useCallback(([lat, lng]) => {
    reverse(lat, lng).then(handleGeocoding)
  }, [])

  const handleMapChange = ({ zoom, center }) => {
    setMapZoom(zoom);
    setMapLocation(center);
  }


  return (
    <>
      <input
        type="text"
        {...inputProps}
        value={query}
        autoComplete="off"
        onChange={handleInputChange}
      />
      {pending && (
        <div class="fa-lg p-2 position-absolute" style={{ right: 0, zIndex: 500 }}>
          <i className="fas fa-spinner fa-spin text-primary" />
        </div>
      )}
      <MapView
        defaultLocation={mapLocation}
        defaultZoom={mapZoom}
        onRegionChange={handleMapChange}
        markers={markers}
        style={{ width: '100%', height: "200px" }}
        onClick={handleMapClick}
      />
    </>
  )
}

export default GeonamesInput
