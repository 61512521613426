import { useCallback } from 'react'

const GEONAMES_URL = "http://api.geonames.org/addressJSON"
// q=Museumplein+6+amsterdam&username=citoyliens"

export const useGeonamesReverse = () => {

  const getAddress = useCallback(async (lat, lng) => {
    if (lat === undefined || lng === undefined) return;
    const url = `${GEONAMES_URL}?lat=${lat}&lng=${lng}&username=${process.env.REACT_APP_GEONAMES_USERNAME}`
    const response = await fetch(url);
    const json = await response.json();
    return json.address
  }, [])
  return getAddress;
};