/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import validator from 'validator';
import { Link, useHistory, useParams } from 'react-router-dom';
import { UserContext } from '../Components/User/UserContextProvider'
import { ACTIONS } from '../Components/User/UserActions';
const loginRegexp = /[a-zA-Z0-9.-_]/g;

const errorTitle = {
  'incorrect-password': "Mot de passe incorrect",
  'user-not-found': "Identifiant incorrect",
  'api-error': "Oups! une erreur est survenue, veuillez recommencer..."
}

function LoginPage() {
  const [focus, setFocus] = useState({ email: false, password: false })
  const [error, setError] = useState({ email: false, password: false })
  const [values, setValues] = useState({ email: "", password: "", stay: false })
  const [valid, setValid] = useState({ email: false, password: false })
  const { state: userState, dispatch } = useContext(UserContext);
  const history = useHistory();
  const { param } = useParams()

  useEffect(() => {
    userState.then(user => {
      if (user.connected) history.replace('/');
      if (user.error) setError({ ...error, global: errorTitle[user.error.error] || errorTitle["api-error"] })
    })
  }, [userState])


  const checkValidation = () => {
    setTimeout(() => {
      const validEmail = validator.isEmail(values.email) || loginRegexp.test(values.email);
      const validPassword = validator.isLength(values.password, { min: 5 });
      setValid({ email: validEmail, password: validPassword })
    }, 500);
  }

  const handleValueChange = (key, value) => {
    setValues({ ...values, [key]: value });
    checkValidation()
  }

  const handleLogin = (e) => {
    e.preventDefault();
    setError({ email: !valid.email, password: !valid.password })
    if (valid.email && valid.password) doLogin()
  }

  async function doLogin() {
    dispatch({ type: ACTIONS.LOGIN, payload: { login: values.email, password: values.password } });
  }

  return (
    <div className="container login-container">
      <div className="row h-100 align-items-center">
        <div className="d-none d-md-block col-md-6">
          <img src="/img/logo.svg" width="100%" alt="" />
        </div>
        <form className="login-form col-md-6">
          <h2><em>Bienvenue sur</em> Citoyliens </h2>
          {param === 'verified' && (
            <p>Votre compte est vérifié, vous pouvez maintenant vous connecter :</p>
          )}
          {error.global && <div className="alert alert-danger" role="alert">
            {error.global}
          </div>}
          <div className={`form-group ${focus.email === true || values.email !== "" ? "focus" : ""}`}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-user" />
                </span>
              </div>

              <input
                type="text"
                autoComplete="off"
                placeholder="Identifiant ou adresse email"
                required
                className={`form-control ${error.email === true ? 'is-invalid' : ''}`}
                onFocus={() => setFocus({ ...focus, email: true })}
                onBlur={() => setFocus({ ...focus, email: false })}
                value={values.email}
                onChange={e => handleValueChange('email', e.target.value)}
              />
              {error.email && <div className="invalid-feedback">Email manquant ou invalide</div>}
            </div>
          </div>
          <div className={`form-group ${focus.password === true || values.password !== "" ? "focus" : ""}`} >
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-lock" />
                </span>
              </div>

              <input
                type="password"
                placeholder="Mot de passe"
                required
                className={`form-control ${error.password === true ? 'is-invalid' : ''}`}
                onFocus={() => setFocus({ ...focus, password: true })}
                onBlur={() => setFocus({ ...focus, password: false })}
                value={values.password}
                onChange={e => handleValueChange('password', e.target.value)}
              />
              {error.password && <div className="invalid-feedback">Mot de passe manquant ou invalide</div>}
            </div>
          </div>

          <div className="form-group form-check">
            <input className="form-check-input" type="checkbox" checked={values.stay} onChange={e => setValues('stay', e.target.checked)} />
            <label> Rester connecté </label>
          </div>

          <button disabled={!valid.email || !valid.password} className="btn btn-primary btn-lg btn-block" onClick={handleLogin}>
            Connexion
        </button>

          <Link to="/register" className="btn btn-link d-block text-center mt-2">Pas encore inscrit ?</Link>
        </form>
      </div>
    </div>
  )
}

export default LoginPage

