import React, { useState, useEffect } from 'react'
import MapView from '../Components/MapView/MapView'
import ThreeColumnsPage from '../Components/Layout/PageLayouts/ThreeColumnsPage'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import _ from 'lodash';

const defaultLocation = [45.158464,1.533651] // Centre de brive

const titles = {
  groups: "Groupes et association citoyliennes",
  users: "Membres citoyliens",
}
const query = {
  groups: `
    query GroupsQuery {
      groups: group {
        id
        name
        address
        location        
      }
    }
  `,
  users: `
    query UsersQuery {
      users: user {
        id
        location
      }
    }
  `
}

function MapPage() {
  const { filter } = useParams();
  const [location, setLocation] = useState(defaultLocation);
  const [markers, setMarkers] = useState([]);
  const [latitude, longitude] = location;
  const [result, reexecuteQuery] = useQuery({
    query: query[filter] || query.groups,
    variables: {latitude, longitude},
    pause: !filter
  });
  const { data } = result;

  useEffect(() => {
    if (data && data[filter]) {
      const markers = data[filter].map(d => {
        const {location, ...other} = d
        const [lat, lng] = _.trim(location, '()', '').split(',');
        return {
          ...other,
          location: [lat, lng]
        }
      })
      setMarkers(markers)
    }
  }, [data, filter])

  const handleMapChange = bounds => {
    // console.log(bounds);
  }

  return (
    <ThreeColumnsPage>
      <h3>{titles[filter]}</h3>
      <MapView defaultLocation={defaultLocation} onRegionChange={handleMapChange} markers={markers}/>
    </ThreeColumnsPage>
  )
}

export default MapPage
