import React, { useContext } from 'react'
import Avatar from '../Ui/Avatar/Avatar'
import { UserContext } from '../User/UserContextProvider'
import SidebarLink from './SidebarLink';

function LeftSidebar() {

  const links = [
    {id: 'home', link: '/', label: 'Accueil', icon: 'home'},
    {id: 'groups', link: '/map/groups', label: 'Carte des groupes', icon: 'map-marker-alt'},
    {id: 'users', link: '/map/users', label: 'Carte des citoyliens', icon: 'street-view'},
    {id: 'friends', link: '/friends', label: 'Mes amis', icon: 'users'},
    {id: 'ads', link: '/ads', label: 'Petites annonces', icon: 'newspaper'},
  ]

  return (
    <div className="left-sidebar">
      <ul className="list-group">
        {links.map(link => <SidebarLink key={`lsidelink_${link.id}`} {...link} />)}
      </ul>
    </div>
  )
}

export default LeftSidebar
