import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

export default function DropdownMenu() {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const categories = [
    {
      title: "Action",
      id: "2",
    },
    {
      title: "Agriculture",
      id: "173,20",
    },
    {
      title: "Circuits-courts",
      id: "3",
    },
    {
      title: "Partenaires",
      id: "4",
    },
    {
      title: "Entraide",
      id: "5,21",
    },
    {
      title: "Citoyenneté",
      id: "7,8,15",
    },
    {
      title: "Ecologie",
      id: "9,11",
    },
    {
      title: "Education",
      id: "12",
    },
    {
      title: "Lieux-Pôles",
      id: "17,19",
    },
    {
      title: "Petites annonces",
      id: "18",
    },
  ];

  useEffect(() => {
    setOpen(false);

    return () => {
      setOpen(false);
    };
  }, [location]);

  return (
    <div className="dropdown">
      <button
        className="btn btn-large dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={(_) => setOpen((_open) => !_open)}
        style={{
          color: "white",
          background: "transparent",
          fontSize: 19,
        }}
      >
        Categories
      </button>
      <div
        className={open ? "dropdown-menu show" : "dropdown-menu"}
        aria-labelledby="dropdownMenuButton"
      >
        {categories?.map((cat) => (
          <Link
            to={`/?cat=${cat?.title?.replace(" ", "-").toLowerCase()}&cat_id=${
              cat.id
            }`}
            key={cat.id}
            className="dropdown-item"
            style={{ color: "black" }}
          >
            {cat.title}
          </Link>
        ))}
      </div>
    </div>
  );
}
