import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../User/UserContextProvider";
import Avatar from "../Ui/Avatar/Avatar";

const testMessages = [
  {
    id: 1,
    user: { id: 12, firstname: "Fontaine", lastname: "Fabrice" },
    date: "17/06/2020 16h37",
    title: "Tu viens lundi ?",
  },
  {
    id: 2,
    user: { id: 13, firstname: "Lambert", lastname: "Gérard" },
    date: "24/06/2020 5h12",
    title: "Je te l'avais bien dit !",
  },
  {
    id: 3,
    user: { id: 14, firstname: "Chritophe", lastname: "Castaner" },
    date: "06/07/2020 18h02",
    title: "Je suis en vacances !",
  },
];

function MessageMenu() {
  const [messages] = useState(testMessages);
  const [show, setShow] = useState(false);
  const [connected, setConnected] = useState(false);
  const { state: userState } = useContext(UserContext);

  userState.then((user) => {
    setConnected(user.connected);
  });

  const handleExtClick = () => {
    document.removeEventListener("click", handleExtClick);
    setShow(false);
  };

  useEffect(() => {
    if (show) document.addEventListener("click", handleExtClick);
  }, [show]);

  if (!connected) return null;

  return (
    <div className="nav-item dropdown">
      <button
        className="btn btn-link nav-link dropdown-toggle"
        onClick={() => setShow(!show)}
      >
        <i className="fa fa-envelope" />
        {messages.length > 0 && (
          <span className="badge badge-danger">{messages.length}</span>
        )}
      </button>
      {messages.length > 0 && (
        <div
          className={`dropdown-menu dropdown-menu-right ${show ? "show" : ""}`}
        >
          {messages.map((message) => (
            <div
              key={`msg_${message.id}`}
              className="d-block pt-2 border-bottom w-100"
            >
              <div className="media">
                <Avatar
                  id={message.user_id}
                  size="sm"
                  className="margin-left-sm"
                />
                <div className="media-body">
                  <div className="d-flex justify-content-between from">
                    <p className="p-0 m-0 pl-2">{`${message.user.firstname} ${message.user.lastname}`}</p>
                    <span className="text-secondary  pr-2 date">
                      <small>{message.date}</small>
                    </span>
                  </div>
                  <h5>{message.title}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MessageMenu;
