import DropdownMenu from "../Layout/Menus/DropdownMenu";
import { Link } from "react-router-dom";
import React from "react";

const PostFilterMenu = () => {
  const filters = [
    { filter: "action", icon: "fist-raised", title: "Agir" },
    { type: "categories", icon: "", title: "Categories" },
    { filter: "local", icon: "map-marker", title: "Local" },
    { filter: "recent", icon: "calendar", title: "Actus" },
    { filter: "events", icon: "bullhorn", title: "Évènements" },
    { filter: "friends", icon: "users", title: "Amis" },
  ];
  return (
    <div className="d-flex flex-grow-1 justify-content-around post-filter">
      {filters.map((filter) =>
        filter?.type === "categories" ? (
          <DropdownMenu />
        ) : (
          <Link
            key={`filter_${filter.filter}`}
            to={`/post/${filter.filter}`}
            className="btn btn-large"
          >
            <i className={`fas fa-fw fa-${filter.icon} mr-2`} />
            {filter.title}
          </Link>
        )
      )}
    </div>
  );
};

export default PostFilterMenu;
