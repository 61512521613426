import React, { useContext, useEffect, useState } from 'react'
import validator from 'validator';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { UserContext } from '../Components/User/UserContextProvider';
import GeonamesInput from '../Components/Location/GeonamesInput';
import { ACTIONS } from '../Components/User/UserActions';

const inputs = [
  {
    name: "login",
    type: 'text',
    icon: 'fas fa-user',
    placeholder: "Identifiant / Pseudo"
  },
  {
    name: "firstname",
    type: 'text',
    icon: 'fas fa-user',
    placeholder: "Prénom"
  },
  {
    name: "lastname",
    type: 'text',
    icon: 'fas fa-user',
    placeholder: "Nom"
  },
  {
    name: "email",
    type: 'text',
    icon: "fas fa-at",
    placeholder: "Adresse email"
  },
  {
    name: "password",
    type: 'password',
    icon: "fas fa-lock",
    placeholder: "Mot de passe"
  },
  {
    name: "password2",
    type: 'password',
    icon: "fas fa-lock",
    placeholder: "Confirmation mot de passe"
  }
]

const apiErrors = {
  "email-exist": "Cet adresse email est déjà enregistrée, utilisez la page de connexion pour vous identifier",
  "login-exist": "Cet identifiant/login est déjà enregistré, utilisez la page de connexion pour vous identifier",
  "missing-identifier": "Login ou adresse email manquants",
  "city-not-found": "Impossible de trouver votre ville dans la base de données"
}


function RegisterPage() {

  const [conditions, setConditions] = useState(false)
  const [captcha, setCaptcha] = useState(false);
  const [focus, setFocus] = useState(null)
  const [error, setError] = useState({})
  const [values, setValues] = useState({})

  const { state: userState, dispatch } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    userState.then(({ registered, error }) => {
      if (!!registered) history.replace('/registered');
      else if (!!error) setError({ global: apiErrors[error.error] || error.error })
    })
  }, [userState, history])

  const handleRegister = (e) => {
    e.preventDefault();
    let errors = {}
    if (!validator.isLength(values.login || "", { min: 5, max: 16 })) errors.login = "Votre login doit contenir entre 5 et 16 caractères"
    if (!validator.isEmail(values.email || "")) errors.email = "Veuillez saisir une adresse email correcte"
    if (!validator.isLength(values.password || "", { min: 5, max: 16 })) errors.password = "Votre mot de passe doit contenir entre 5 et 16 caractères"
    if (values.password !== values.password2) errors.password2 = "Vos mots de passe ne correspondent pas"
    if (!conditions) errors.conditions = "Vous devez accepter les conditions d'utilisation pour vous inscrire"
    if (!captcha) errors.captcha = "Êtes-vous un robot ??"
    if (!!Object.values(errors).length) setError(errors)
    else {
      dispatch({ type: ACTIONS.REGISTER, payload: values });
    }
  }

  const handleValueChange = (e) => {
    const { target: { name, type, value, checked } } = e;
    setValues(v => ({
      ...v,
      [name]: type === "checkbox" ? checked : value
    }))
    setError(error => ({ ...error, [name]: null }))
  }

  const handleLocationChange = (geolocation) => {
    const { lat, lng, adminCode4, locality, postalcode, street, housenumber } = geolocation

    setValues(v => ({
      ...v,
      location: `(${lat},${lng})`,
      city_insee: Number(adminCode4),
      address: `${housenumber ? `${housenumber}, ` : ''}${street}, ${postalcode} ${locality}`
    }))
  }

  const renderInputs = () => {
    return inputs.map(({ name, type, icon, placeholder }, index) => (
      <div
        key={`input_${index}`}
        className={`form-group ${focus === name || values[name] !== "" ? "focus" : ""}`}
      >
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className={icon} />
            </span>
          </div>
          <input
            type={type}
            autoComplete="off"
            placeholder={placeholder}
            className={`form-control ${!!error[name] ? 'is-invalid' : ''}`}
            onFocus={() => setFocus(name)}
            name={name}
            value={values[name] || ""}
            onChange={handleValueChange}
          />
          {error[name] && <div className="invalid-feedback">{error[name]}</div>}
        </div>
      </div>

    ))
  }

  return (
    <div className="container login-container">
      <div className="row h-100 align-items-center">
        <div className="d-none d-md-block col-md-6">
          <img src="/img/logo.svg" width="100%" alt="" />
        </div>
        <form
          className="login-form col-md-6"
          onSubmit={handleRegister}
        >
          <h2>Inscription sur<br />Citoyliens </h2>
          {error.global && (
            <div className="alert alert-danger" role="alert">
              {error.global}
            </div>
          )}
          {renderInputs()}
          <div className={`form-group ${focus === 'geonames' ? "focus" : ""}`}
          >
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-map-signs" />
                </span>
              </div>
              <GeonamesInput
                name="geonames"
                onChange={handleLocationChange}
                className={`form-control ${!!error['geonames'] ? 'is-invalid' : ''}`}
                placeholder="Votre adresse postale"
              />
              {error.location && <div className="invalid-feedback">{error.location}</div>}
            </div>
          </div>
          <label>
            <input className="w3-check" type="checkbox" name="contact" checked={values.contact ? "checked" : ""} onChange={handleValueChange} />
              &nbsp; J'accepte d'être contacté par d'autres membres
          </label>
          <label>
            <input className="w3-check" type="checkbox" name="map" checked={values.map ? "checked" : ""} onChange={handleValueChange} />
              &nbsp; J'accepte d'apparaitre sur la carte des membres
          </label>
          <label>
            <input className="w3-check" type="checkbox" checked={conditions ? "checked" : ""} onChange={(e) => setConditions(e.target.checked)} />
              &nbsp; J'accepte les <Link to="/terms">conditions d'utilisation et de confidentialité</Link> du site <Link to="/">citoyliens.fr</Link>
          </label>
          {error.conditions && <div className="invalid-feedback d-block">{error.conditions}</div>}

          <div className="my-2 d-flex justify-content-center">
            <ReCAPTCHA
              sitekey="6LdovioUAAAAAAG_r8jGqElEBZU1j05P_qExHGZS"
              onChange={value => setCaptcha(value ? true : false)}
            />
            {error.captcha && <div className="invalid-feedback d-block">{error.captcha}</div>}
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block my-2"
          >
            Créer mon compte Citoyliens !
          </button>

          <Link to="/login" className="btn btn-link d-block text-center mt-2">Déjà inscrit ?</Link>
        </form>
      </div>
    </div>
  )
}

export default RegisterPage

