import React from 'react'

function Avatar({id, size, title, className, ...otherProps}) {
  // const src = `${process.env.REACT_APP_AVATAR_ROOT}/${id}.png`;
  const src = `/img/avatar2.png`;
  
  let style = {};
  switch (size) {
    default:
    case "md":
      style={height: 50, width: 50}
      break;
    case "sm":
      style={height: 23, width: 23}
      break;
    case "lg":
      style={height: 100, width: 100}
      break;
    }

  return (
    <img 
      src={src}
      className={`rounded-circle ${className}`}
      style={style}
      alt={title || ""}
      title={title || "avatar"}
      {...otherProps}
    />
  )
}

export default Avatar
