import React from 'react'

function RightSidebar() {
  return (
    <div className="right-sidebar">
      Right sidebar      
    </div>
  )
}

export default RightSidebar
