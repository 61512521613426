import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from '../User/UserContextProvider'
import Avatar from '../Ui/Avatar/Avatar';

const testAlerts = [
  { id: 1, link: "/post/123", user_id: 12, title: "Bienvenue sur le nouveau citoyliens" },
  { id: 2, link: "/post/123", user_id: 4, title: "Invitation du goupe Association Gillets Verts" },
  { id: 3, link: "/post/123", user_id: 6, title: "Votre demande d'adhésion est accéptée !" },
]

function AlertMenu() {
  const [alerts,] = useState(testAlerts)
  const [show, setShow] = useState(false);
  const [connected, setConnected] = useState(false);
  const { state: userState } = useContext(UserContext)

  userState.then(user => {
    setConnected(user.connected)
  })

  const handleExtClick = () => {
    console.log('ext click');
    document.removeEventListener('click', handleExtClick)
    setShow(false);
  }

  useEffect(() => {
    if (show) document.addEventListener('click', handleExtClick)
  }, [show])

  if (!connected) return null;

  return (
    <div className="nav-item dropdown">
      <button className="btn btn-link nav-link dropdown-toggle" onClick={() => setShow(!show)}>
        <i className="fa fa-bell" />
        {alerts.length > 0 && <span className="badge badge-danger">{alerts.length}</span>}
      </button>
      {alerts.length > 0 && (
        <div className={`dropdown-menu dropdown-menu-right ${show ? 'show' : ''}`}>
          {alerts.map(alert =>
            <div key={`msg_${alert.id}`} className="d-block pt-2 border-bottom w-100">
              <div className="media">
                <Avatar id={alert.user_id} size="sm" className="margin-left-sm" />
                <div className="media-body">
                  <div className="d-flex justify-content-between from">                    
                    <span className="text-secondary  pr-2 date"><small>{alert.date}</small></span>
                  </div>
                  <h5>{alert.title}</h5>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AlertMenu
