import React from 'react'
import { Link, } from 'react-router-dom';

function RegisteredPage() {
  return (
    <div className="container login-container">
      <div className="row h-100 align-items-center">
        <div className="d-none d-md-block col-md-6">
          <img src="/img/logo.svg" width="100%" alt="" />
        </div>
        <form
          className="login-form col-md-6"
        >
          <h1>Votre compte est enregistré</h1>
          <p>
            Vous allez recevoir un email contenant un lien de validation.<br />
            Une fois votre compte vérifié vous pourrez utiliser votre compte...
          </p>

          <Link to="/" className="btn btn-link d-block text-center mt-2">Retour à l'acceuil</Link>
        </form>
      </div>
    </div>
  )
}

export default RegisteredPage

