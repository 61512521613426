import PostFilterMenu from "../Components/Post/PostFilterMenu";
import PostList from "../Components/Post/PostList";
import React from "react";
import ThreeColumnsPage from "../Components/Layout/PageLayouts/ThreeColumnsPage";

function HomePage() {
  return (
    <ThreeColumnsPage>
      <PostFilterMenu />
      <PostList />
    </ThreeColumnsPage>
  );
}

export default HomePage;
