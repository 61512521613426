import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

function PostCard(props) {
  const {
    id,
    title,
    excerpt,
    _embedded,

    // user, group,
    // address, city, postcode, location,
    // start_at, end_at, created_at, price
  } = props;

  const [text, setText] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const _text = new DOMParser()
      .parseFromString(excerpt.rendered, "text/html")
      ?.documentElement.querySelectorAll("p")[1]
      ?.innerText?.split("Lire la suite")[0];
    setText(_text);
  }, [excerpt.rendered]);

  const handlePostDetails = (_) => {
    history.push(`/posts/${id}`);
  };

  return (
    <div className=" col-sm-12 col-md-6 p-2">
      <div className="post-card card p-2 h-100">
        <div
          className="card-title"
          onClick={handlePostDetails}
          style={{ cursor: "pointer" }}
        >
          <div style={{ minHeight: "250px" }}>
            <img
              onClick={handlePostDetails}
              src={
                "wp:featuredmedia" in _embedded
                  ? _embedded["wp:featuredmedia"][0]?.source_url
                  : ""
              }
              alt={title.rendered}
              style={{
                width: "100%",
                display: "block",
                maxHeight: 350,
                cursor: "pointer",
              }}
              className="card-img-top"
            />
          </div>
        </div>
        <div className="card-body">
          <h2
            onClick={handlePostDetails}
            style={{ cursor: "pointer" }}
            dangerouslySetInnerHTML={{ __html: title?.rendered }}
          />
          <p>{text}</p>
          <Link to={`/posts/${id}`}>Lire la suite</Link>
          <hr />
          <div>
            <h6 className="bold">Categories:</h6>
          </div>
          <div
            className="d-flex align-items-center mb-2"
            style={{ columnGap: 10, flexWrap: "wrap" }}
          >
            {_embedded["wp:term"][0].map((cat) => (
              <Link to={`/?cat=${cat.slug}&cat_id=${cat.id}`} key={cat.id}>
                {cat.name}
              </Link>
            ))}
          </div>
        </div>

        <div className="card-footer d-flex justify-content-between">
          <button className="btn btn-light" title="partager">
            <i className="fa text-primary fa-share-alt" />
            <span className="badge badge-success">15</span>
          </button>
          <button className="btn btn-light" title="partager">
            <i className="fa text-success fa-thumbs-up" />
            <span className="badge badge-success">15</span>
          </button>
          <button className="btn btn-light" title="partager">
            <i className="fa text-secondary fa-thumbs-down" />
            <span className="badge badge-success">3</span>
          </button>
          <button className="btn btn-light" title="partager">
            <i className="fa text-warning fa-comment" />
            <span className="badge badge-success">4</span>
          </button>
          <button className="btn btn-light" title="partager">
            <i className="fa text-info fa-route" />
            <span className="badge badge-success">3</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PostCard;
