import React, { useCallback, useEffect, useState } from "react";

import PostCard from "./PostCard";
import api from "../Axios/Axios";
import { useParams } from "react-router-dom";
import useQuery from "../../Hooks/useQuery";

function PostList() {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [currentCat, setCurrentCat] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [endPoint, setEndPoint] = useState(
    // `/posts?per_page=10&page=${page}&_embed`
    null
  );

  let query = useQuery();
  let params = useParams();

  const handlePosts = useCallback(async () => {
    try {
      if (!endPoint) {
        return;
      }
      setLoading(true);
      const response = await api.get(endPoint);
      setPosts((_posts) => [..._posts, ...response?.data]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [endPoint]);

  useEffect(() => {
    handlePosts();
  }, [endPoint, handlePosts]);

  useEffect(() => {
    console.log("LATEST POSTS", posts);
  }, [posts]);

  useEffect(() => {
    let hasCat = query.has("cat");
    if (params.filter === "action") {
      if (currentFilter !== "action") {
        setCurrentCat("action");
        setCurrentFilter("action");
        setPosts([]);
        setPage(1);
      }
      setEndPoint(`/posts?per_page=10&page=${page}&categories=2&_embed`);
    } else if (hasCat) {
      let _cat = query.get("cat_id");
      if (_cat !== currentCat) {
        setPosts([]);
        setCurrentCat(_cat);
        setPage(1);
      }
      setEndPoint(
        `/posts?per_page=10&page=${page}&categories=${query.get(
          "cat_id"
        )}&_embed`
      );
    } else {
      setEndPoint(`/posts?per_page=10&page=${page}&_embed`);
    }
  }, [params, query, page]);

  const handleLoadMore = async () => {
    setPage((_page) => _page + 1);
  };

  return (
    <div className="row post-list w-100">
      {posts.map((post) => (
        <PostCard key={`post_${post.id}`} {...post} />
      ))}
      {loading && (
        <div className="d-flex w-100 justify-content-center">
          <h4 className="m-auto text-center">Loading...</h4>
        </div>
      )}
      {!loading && (
        <div className="d-flex justify-content-center w-100">
          <button className="btn btn-light" onClick={handleLoadMore}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
}

export default PostList;
