import React from 'react';
import GraphqlProvider from './Components/Graphql/GraphqlProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from './Navigation/Navigation';
import UserContextProvider from './Components/User/UserContextProvider';

function App() {
  return (
    <UserContextProvider>
      <GraphqlProvider>
        <Router>
          <Navigation />
        </Router>
      </GraphqlProvider>
    </UserContextProvider>
  );
}

export default App;
