import React, { useEffect, useState } from "react";

import ThreeColumnsPage from "../Layout/PageLayouts/ThreeColumnsPage";
import api from "../Axios/Axios";
import { useParams } from "react-router-dom";

export default function SinglePost() {
  let params = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    const _id = params.id;
    const _url = `/posts/${_id}?_embed`;
    api
      .get(_url)
      .then((res) => {
        setPost(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  }, [params.id]);

  return (
    <ThreeColumnsPage>
      <div>
        {error && (
          <div className="d-flex w-100 text-center">
            {"Error occured while loading post..."}
          </div>
        )}
        {loading ? (
          <h4 className="text-center">Loading...</h4>
        ) : (
          <div>
            <h2
              className="text-center"
              dangerouslySetInnerHTML={{ __html: post?.title?.rendered }}
            />
            <img
              className="w-100 d-block m-auto my-1"
              src={post?._embedded["wp:featuredmedia"][0]?.source_url}
              alt={post?.title?.rendered || ""}
            />
            <div className="container">
              <div
                dangerouslySetInnerHTML={{ __html: post?.content?.rendered }}
              />
            </div>
          </div>
        )}
      </div>
    </ThreeColumnsPage>
  );
}
