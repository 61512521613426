import React, { useContext, useState } from 'react'
import Avatar from '../Ui/Avatar/Avatar'
import { Link } from 'react-router-dom'
import { UserContext } from './UserContextProvider'
import { ACTIONS } from './UserActions'

function UserMenu() {
  const { state: userState, dispatch } = useContext(UserContext)
  const [connected, setConnected] = useState(false);
  userState.then(user => {
    setConnected(user.connected)
  })
  if (connected) return <ConnectedMenu dispatch={dispatch}/>
  else return <ConnexionButton />
}

const ConnectedMenu = ({dispatch}) => {
  const [show, setShow] = useState(false)
  const userLinks = [
    { icon: "user", link: "user/profile", title: "Mon profil" },
    { icon: "cogs", link: "user/settings", title: "Mes préférences" },
    { icon: "users", link: "user/groups", title: "Mes groupes" },
  ]
  return (
    <div className="nav-item dropdown d-none d-md-block">
      <button className="btn btn-link nav-link dropdown-toggle" onClick={() => setShow(!show)}>
        <Avatar id={1} size="md" />
      </button>
      <div className={`dropdown-menu dropdown-menu-right ${show ? 'show' : ''}`}>
        {userLinks.map((ul, i) => (
          <Link
            key={`userlink_${i}`}
            to={ul.link}
            className="dropdown-item"
          >
            <i className={`fa fa-fw fa-${ul.icon} mr-2`} />
            {ul.title}
          </Link>
        ))}
        <div className="dropdown-divider"></div>
        <button className="dropdown-item" onClick={() => dispatch({type: ACTIONS.LOGOUT})}>
          <i className={`fa fa-fw fa-sign-out-alt mr-2`} />
          Déconexion
        </button>
      </div>
    </div>
  )
}

const ConnexionButton = () => {
  return (
    <div className="w3-hide-small w3-right">
      <Link to={'/login'} className="w3-bar-item w3-button w3-theme-c1">
        <i className={`fa fa-fw fa-signin-alt mr-2`} />
        Connexion
      </Link>
    </div>
  )
}

export default UserMenu
