import { Route, Switch } from "react-router-dom";

import Header from "../Components/Layout/Header/Header";
import HomePage from "../Containers/HomePage";
import LoginPage from "../Containers/LoginPage";
import MapPage from "../Containers/MapPage";
import PostPage from "../Containers/PostPage";
import React from "react";
import RegisterPage from "../Containers/RegisterPage";
import RegisteredPage from "../Containers/RegisteredPage";

function Navigation() {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/posts/:id">
          <PostPage />
        </Route>
        <Route path="/post/:filter?/">
          <HomePage />
        </Route>
        <Route path="/map/:filter?/">
          <MapPage />
        </Route>
        <Route path="/login/:param?">
          <LoginPage />
        </Route>
        <Route path="/register">
          <RegisterPage />
        </Route>
        <Route path="/registered">
          <RegisteredPage />
        </Route>
      </Switch>
    </>
  );
}

export default Navigation;
