
import { setToken } from './UserToken';

const USER_DATA = "citoylien-user"

const getUser = async () => {
  const persisted = await localStorage.getItem(USER_DATA);
  return persisted ? JSON.parse(persisted) : null;
}
const setUser = async (state) => {
  const persisted = await localStorage.setItem(USER_DATA, JSON.stringify(state));
  return persisted ? true : false;
}

export const initialState = async (overwrite) => {
  const user = await getUser();
  return user && !overwrite
    ? user
    : {
      id: null,
      connected: false,
      registered: false,
      role: "guest",
      error: null
    }
}

export const ACTIONS = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  REGISTER: 'register',
  SET_LOCATION: 'set-location',
  GET_LOCATION: 'get-location'
}

const userLogin = async (state, { login, password }) => {
  const loginQuery = `${process.env.REACT_APP_AUTH_API_URL}login/${login}/${password}`
  const response = await fetch(loginQuery);
  if (response.status !== 401 && response.status !== 200) {
    return { ...state, error: 'api-error ' + response.status }
  }

  const json = await response.json();

  const authorization = response.headers.get('Authorization');
  if (authorization) {
    const token = String(authorization).split(' ')[1]
    await setToken(token);
  }

  const newState = { ...state, ...json, connected: json.id ? true : false }
  setUser(newState)
  return newState;
}

const userRegister = async (state, userData) => {
  const registerQuery = `${process.env.REACT_APP_AUTH_API_URL}register`

  const response = await fetch(registerQuery, {
    method: "POST",
    body: JSON.stringify(userData),
    headers: { "Content-type": "application/json; charset=utf-8" }
  });

  if (response.status !== 401 && response.status !== 200) {
    return { ...state, error: 'api-error ' + response.status }
  }

  const json = await response.json();

  return response.status !== 200
    ? { ...state, error: json.error }
    : { ...state, registered: true }
}

const userLogout = async () => {
  const defaultState = initialState(true);
  setUser(defaultState)
  return defaultState;
}
const userGetLocation = (state) => {
  return state;
}
const userSetLocation = (state, payload) => {
  return state;
}

const UserActions = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN: return userLogin(state, action.payload);
    case ACTIONS.LOGOUT: return userLogout();
    case ACTIONS.REGISTER: return userRegister(state, action.payload);
    case ACTIONS.SET_LOCATION: return userSetLocation(state, action.payload);
    case ACTIONS.GET_LOCATION: return userGetLocation(state);
    default: return state;
  }
}

export default UserActions;