import React from 'react'
import LeftSidebar from '../../Sidebar/LeftSidebar'
import RightSidebar from '../../Sidebar/RightSidebar'


function ThreeColumnsPage({children}) {
  return (
      <div className="row m-0 p-3">
        <div className="d-none d-md-block col col-2">
          <LeftSidebar />
        </div>
        <div className="col col-md-8 col-sm-12 p-0">
          {children}
        </div>
        <div className="d-none d-md-block col col-2">
          <RightSidebar />
        </div>
      </div>
  )
}

export default ThreeColumnsPage
